import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Message from '../components/message'
import { appointmentStatus, confirmBPAppointment, cancelBPAppointment } from '../utils/booking-api'
import { API } from 'aws-amplify'
import { getAppointment }  from '../graphql/queries'
import { createAppointment, updateAppointment } from '../graphql/mutations'
import { aptTimeString } from '../utils/date-formatter'
import { localDate } from '../utils/date-formatter'
import moment from 'moment'
import Amplify from 'aws-amplify'
import config from '../aws-exports'
import { followupApptType, initialApptType, counsellingApptType } from '../utils/bp-codes' 

Amplify.configure(config)

const MyAppointment = (props) => {
  const [id] = useState(queryString.parse(props.location.search).id)
  const [apt, setApt] = useState(null)
  const [patient, setPatient] = useState('')
  const [aptDate, setAptDate] = useState('')
  const [aptTime, setAptTime] = useState('')
  const [provider, setProvider] = useState('')
  const [aptDescription, setAptDescription] = useState('')
  const [doctor, setDoctor] = useState('')
  const [cancelled, setCancelled] = useState(false)
  const [attendanceMessage, setAttendanceMessage] = useState('')
  // confirm or cancel
  const [attendance, setAttendance] = useState('confirm')
  const [triggerAttendanceMessage, setTriggerAttendanceMessage] = useState(false)
  const [disableCancel, setDisableCancel] = useState(false)
  const [disableConfirm, setDisableConfirm] = useState(false)
  // const inclusionProviders = [ "Dr Ian Rebello", "Mr Matthew Kong", "Ms Kelly Yu", "Ms Emilia Tiainen"]
  const inclusionProviders = [ "Dr Ian Rebello"]
  // Allied health requires 24 hours notice and GP requires 2 hours
  const [alliedHealth, setAlliedHealth] = useState(false)
  const [noticeHours, setNoticeHours] = useState(2)

  useEffect(() => {
    const getAppointmentStatus = async () => {
      const result = await appointmentStatus(id)
      setApt(result)
    }
    getAppointmentStatus()
  }, [])

  useEffect(() => {
    if (apt) {
      setPatient(apt.patient)
      setAptDate(apt.aptDate)
      setAptTime(apt.aptTime)
      setProvider(apt.provider)
      setAptDescription(apt.aptDescription)
      setDoctor(apt.provider)
      setCancelled(apt.status === 2 ? true : false)
      setAlliedHealth(inclusionProviders.includes(apt.provider))
      setNoticeHours(inclusionProviders.includes(apt.provider) ? 24 : 2)
    }
  },[apt])

  useEffect(() => {
    const now = new Date().getTime()
    const slot = localDate(aptDate, aptTime).getTime()
    setDisableCancel((slot - now) < noticeHours * 3600000 ? true : false)
  // }, [aptDate, aptTime, provider])
  // Deliberately remove dependency to check on every render. This should fix appointment being canceled after the deadline.
  // When they open this page before the deadline, the Cancel button is enabled. If they keep the page open, they would be
  // able to cancel after the deadline. Render every time should fix this issue.
  })

  const confirmBooking = () => {
    setAttendanceMessage(`You are confirming the appointment with ${doctor} on ${aptDate} at ${aptTime}`)
    setAttendance('confirm')
    setTriggerAttendanceMessage(!triggerAttendanceMessage)
  }

  const cancelBooking = () => {
    const now = new Date().getTime()
    const slot = localDate(aptDate, aptTime).getTime()
    const cancelAllowed = (slot - now) < noticeHours * 3600000 ? false : true

    if (cancelAllowed) {
      setAttendanceMessage(`You are cancelling the appointment with ${doctor} on ${aptDate} at ${aptTime}`)
      setAttendance('cancel')
      setTriggerAttendanceMessage(!triggerAttendanceMessage)
    } else {
      alert(`Sorry, it's too late to cancel your appointment. A Did Not Attend event will be recorded in your account.`)
    }
  }

  const addAppointmentHistory = async (id) => {
    try {
      const result = await API.graphql(
        {
          query: getAppointment,
          variables: {
            id: id.toString()
          },
          authMode: 'AWS_IAM'
        })

      if (result.data.getAppointment === null) {
        API.graphql({
          query: createAppointment, 
          variables: {
            input: {
              id: id.toString(),
              patientID: patient,
              bookedBy: 'Clinic',
              canceledBy: 'SMS',
              appointmentDate: aptDate,
              appointmentTime: new Date(`${aptDate} ${aptTime}`)
            }
          },
          authMode: 'AWS_IAM'
        })
      } else {
        API.graphql({
          query: updateAppointment,
          variables: { 
            input: {
              id: id.toString(),
              canceledBy: 'SMS'
            }
          },
          authMode: 'AWS_IAM'
        })
      }
    } catch (err) {
      console.error('Amplify updateAppointment error...: ', err)
    }

  }

  const confirming = async () => {
    let code
    if (attendance === 'confirm')
      code = await confirmBPAppointment(id)
    else
      code = await cancelBPAppointment(id)

    if ((code === 0 && attendance === 'cancel') || (code === 1 && attendance === 'confirm')) {
      alert(`Your appointment has been ${attendance}ed successfully`)
      setDisableConfirm(true)
      setDisableCancel(true)
      addAppointmentHistory(id)
    } else {
      alert(`Unable to ${attendance} your appointment. Please call 91899667.`)
    }
  }
  
  return (
    <Container maxWidth="xs" style={{marginBottom: 40}}>
      <Typography variant="h5" style={{marginTop: 40, marginBottom: 20}} align="center">
        Your appointment details
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {`Patient : ${patient}`}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {`Appointment Date : ${aptDate}`}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {`Appointment Time : ${aptTime}`}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {`Appointment Type : ${aptDescription}`}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {`Practitioner : ${doctor}`}
      </Typography>
      <Grid container justify="space-around" style={{marginTop: 40, marginBottom: 40}}>
      {cancelled && <Typography variant='h5' align="center">
        You already cancelled your appointment.
      </Typography>}
        {alliedHealth && !cancelled && <Grid item>
          <Button
            onClick={confirmBooking}
            type="submit"
            color="primary"
            variant="contained"
            disabled={disableConfirm}            
          >
            Confirm
          </Button>
        </Grid>}
        {!cancelled && <Grid item>
          <Button
            onClick={cancelBooking}
            type="submit"
            color="primary"
            variant="contained"
            disabled={disableCancel}
          >
            Cancel
          </Button>
        </Grid>}
      </Grid>
      <Typography variant='caption' align="center">
        {`*If you need to cancel your appointment, please do it no later than ${noticeHours} hours before the appointment time.`}
      </Typography>
      <Message 
        triggerOpen={triggerAttendanceMessage} 
        initOpen={false}
        message={attendanceMessage}
        action="confirm"
        cb={confirming}
      />
    </Container>
  )
}

export default MyAppointment