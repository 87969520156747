import React from 'react'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCol } from 'mdbreact'
import Link from '@material-ui/core/Link'
import { GatsbyImage } from "gatsby-plugin-image"

const Card = ({image, title, description, link, linkText}) => {
    return (
        <MDBCol style={{ maxWidth: "22rem", minWidth: "300px" }}>
            <MDBCard style={{ marginBottom: "20px" }}>
            <GatsbyImage className="img-fluid" image={image} alt={title}  waves />
                <MDBCardBody>
                    <MDBCardTitle>{title}</MDBCardTitle>
                    <MDBCardText>{description}</MDBCardText>
                    {/* <Link color='primary' href={link} underline="always">{linkText}</Link> */}
                    <Link color='primary' href={link} underline="always">Learn More</Link>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    )
}

export default Card
