import React, { useState, useEffect } from 'react'
import Content from '../components/content'
import titleImg from '../images/bg8.jpg'
import { Storage } from 'aws-amplify'
import Amplify from 'aws-amplify'
import config from '../aws-exports'
import axios from 'axios'
Amplify.configure(config)

const GPAd = () => {
  const [gpadUrl, setGpadUrl] = useState('')
  const [html, setHtml] = useState('')

  useEffect(() => {
    Storage.get('gpad.html')
      .then(data => {
        setGpadUrl(data)
      })
      .catch(err => {
        console.error('error fetching gpad.html')
      })
  }, [])

  useEffect(() => {
    const fetchGpAd = async () => {
      if (gpadUrl) {
        try {
          const config = {
            method: 'get',
            headers: {
              "Content-Type": "application/json"
            },
            url: gpadUrl
          }
          const result = await axios(config)
          setHtml(result.data)
        }
        catch (err) {
          console.error('error reading gpad.html')
        }
      }
    }

    fetchGpAd()

  }, [gpadUrl])

  return (
    <Content title='Join our team' titleImg={titleImg} tagline='Passion led us here'>
      <div style={{fontSize: "large"}} dangerouslySetInnerHTML={{ __html: html }} />
    </Content>
  )
}

export default GPAd