import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Button from '@material-ui/core/Button'
import Content from './content'
import { navigate } from 'gatsby'
import { isAdmin } from './auth/app-user'
import titleImg from '../data/general-information/bg6.jpg'
import { Storage } from 'aws-amplify'
import axios from 'axios'

const UpdateGPAd = () => {
  const [gpadUrl, setGpadUrl] = useState('')
  const [html, setHtml] = useState('')

  useEffect(() => {
    if (!isAdmin())
      navigate('/')
  }, [])

  useEffect(() => {
    Storage.get('gpad.html')
      .then(data => {
        setGpadUrl(data)
      })
      .catch(err => {
        console.error('error fetching gpad.html')
      })
  }, [])

  useEffect(() => {
    const fetchGpad = async () => {
      if (gpadUrl) {
        try {
          const config = {
            method: 'get',
            headers: {
              "Content-Type": "application/json"
            },
            url: gpadUrl
          }
          const result = await axios(config)
          setHtml(result.data)
        }
        catch (err) {
          console.error('error reading gpad.html')
        }
      }
    }

    fetchGpad()

  }, [gpadUrl])

  const handleUpdate = async () => {
    try {
      await Storage.put(
        "gpad.html", 
        html, 
        {
          contentType : 'application/html'
        }
      )      
      navigate('/')
    } catch(err) {
      console.error("Error occurred: ", err)
    }
  }

  return (
    <Content title='Update GP Ad' titleImg={titleImg}>
      <ReactQuill theme="snow" value={html} onChange={setHtml}/>      
      <Button 
        className="mt-3"
        variant="contained" 
        onClick={handleUpdate} 
        color="primary" 
        disabled={html === ''}
      >
        Update
      </Button>
    </Content>
  )
}

export default UpdateGPAd